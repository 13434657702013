@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;900&display=swap);
/* ————————————————————————————————————————————————
Colors Variables
————————————————————————————————————————————————— */
:root {
    --primary-blue: #007CBA;
    --primary-blue-light: #2391c8;
    --primary-blue-lighter: #89d8ff;
    --primary-blue-dark: #00628A;
    --primary-blue-darker: #015779;
    --secondary-design: #B12851;
    --secondary-learn: #37A168;
    --section-headlines: #353535;
    --light-grey: #c6cccf;
    --mid-grey1: #868686;
    --mid-grey: #545657;
    --dark-gray: #202120;
    --darker-gray: #191a19;
    --nav-filter-links: #6C6C6C;
}



.g_bg-color {
    background: #202120;
    background: var(--dark-gray);
}
.g_bg-lighter-color {
    background: #c6cccf;
    background: var(--light-grey);
}
.g_secondary-blue-color {
    background: #00628A;
    background: var(--primary-blue-dark);
}

.g_secondary-design-color {
    background: #B12851;
    background: var(--secondary-design);
}

.g_secondary-learn-color {
    background: #37A168;
    background: var(--secondary-learn);
}


.g_section-headlines-color {
    color: #353535;
    color: var(--section-headlines);
}
.g_white-color {
    color: white;
}

/* Any class pre-pended with "g_" is a custom class that is applied globally */

/* ————————————————————————————————————————————————
Base Styles
————————————————————————————————————————————————— */


::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}
body {
    scrollbar-width: thin;
    scrollbar-color: #00628A #191a19;
    scrollbar-color: var(--primary-blue-dark) var(--darker-gray);
}
::-webkit-scrollbar-track {
    background: #191a19;
    background: var(--darker-gray);
}
::-webkit-scrollbar-thumb {
    background-color: #00628A;
    background-color: var(--primary-blue-dark) ;
    border-radius: 6px;
    border: 3px solid #191a19;
    border: 3px solid var(--darker-gray);
}


body {
    margin: 0;
    font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #202120;
    background: var(--dark-gray);
    font-weight: 600;
    font-size: 16px;
}

header {
    text-align: center;
    padding: 170px 0 190px;
}

hr {
    border-top: solid #007CBA 9px;
    border-top: solid var(--primary-blue) 9px;

    /* width: 210px;   */
    border-radius: 20px;
    width: 9px;
}

a {
    color: white;
    transition: ease 0.3s;
}

a:hover {
    color: white;
}

.g__footer-pin {
    min-height: calc(100vh - 315px);
}

s {
    display: inline-block;
    text-decoration: none;
    position: relative;
}

s:after {
    content: '';
    display: block;
    width: 100%;
    height: 63%;
    position: absolute;
    top: 0;
    left: 0;
    border-bottom: 2px solid;
}



/* ————————————————————————————————————————————————
Fonts
————————————————————————————————————————————————— */
h1 {
    font-weight: 900;
    font-size: 36px;
    line-height: 140%;
    max-width: 350px;
    margin: 0 auto;
}

h2 {
    font-weight: 900;
    font-size: 180px;
    line-height: 140%;
    text-align: center;
    color: #353535;
    color: var(--section-headlines);
    margin-top: -5rem;
}

/* h3 is given several unique styles in the areas it is used. */

h4 {
    font-weight: 900;
    font-size: 16px;
}

h5 {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
}

h6 {
    font-weight: 600;
    font-size: 16px;
    margin: 0;
}

p {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.5;
    color: #c6cccf;
    color: var(--light-grey);
}

.g_txt-bold {
    font-weight: 900;
}

.g__body-lg {
    font-size: 22px;
    /* margin-bottom: 30px; */
    color: white;
}


/* ————————————————————————————————————————————————
Forms Overrides
————————————————————————————————————————————————— */
.form-control {
    background-color: #353535;
    background-color: var(--section-headlines);
    color: aliceblue;
    border: 1px #202120 solid;
    border: 1px var(--dark-gray) solid;
    /* margin-bottom: 30px; */
    height: 50px;
    padding: 15px;
}

.form-control:focus {
    background-color: rgb(29, 29, 29);
    color: rgb(223, 223, 223);
    border-color: #00628A;
    border-color: var(--primary-blue-dark);
}

label {
    color: #c6cccf;
    color: var(--light-grey);
}

.invalid-feedback {
    display: block;
}


/* ————————————————————————————————————————————————
Buttons
————————————————————————————————————————————————— */

.btn-primary {
    background-color: #00628A;
    background-color: var(--primary-blue-dark);
    width: 130px;
}

.btn-primary,
.btn,
.contact__submit-btn {
    border-color: currentColor;
    border-color: initial;
    border: none;
}

button {
    background: none;
    border: none;
}


/* ————————————————————————————————————————————————
Flex Utils
————————————————————————————————————————————————— */
.g_justify-end {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
}



/* ————————————————————————————————————————————————
Hidden Util
————————————————————————————————————————————————— */
.hidden {
    display: none;
}




/* ————————————————————————————————————————————————
Section Margins
————————————————————————————————————————————————— */
.g_negative-margin {
    margin-top: -6.5rem;
    margin-right: auto;
    margin-left: auto;
}


/* ————————————————————————————————————————————————
Project Styles
————————————————————————————————————————————————— */
.g_projects-container {
    /* margin-top: -170px; */


}


.g_meta-container {
    background: #007CBA;
    background: var(--primary-blue);
    position: relative;
    top: -126px;
    /* min-height: 252px; */
    border-radius: 18px;
    z-index: 1;
    margin-bottom: 1rem;
    color: white;

}

.g_project-img {
    width: 100%;
    min-height: 200px;
    border-radius: 18px;
    transition: ease .5s;
    z-index: 3000;
}

.g_card-slide,
.g__design-project__card-slide {
    position: relative;
    top: 0;
    z-index: 2;
    color: #000;
    color: initial;
    padding: 0;
    border-radius: 23px;
    display: inline-block;
    box-shadow: 0 4px 23px 0 rgba(0,0,0,0.65);
    transition: ease .5s;
}

.g_card-slide:hover,
.g__design-project__card-slide:hover {
    box-shadow: 0 20px 50px 0 rgba(0,0,0,0.95);
    transition: ease .5s;
}

.g_card-slide:focus,
.g__design-project__card-slide:focus {
    border: none;
    outline: none;
}

.g_card-slide-up {
    top: -30px;
    z-index: 2;
    transition: ease .5s;
    /* top: 176px; */
}


.g__design-project__card-slide {
    top: 50px;
}



/* ————————————————————————————————————————————————
Accordion Overrides
————————————————————————————————————————————————— */

.accordion>.card {
    overflow: visible;
}

.card {
    position: static;
    position: initial;
    display: block;
    min-width: 0;
    word-wrap: break-word;
    background: none;
    background-clip: border-box;
    border: none;
    border-radius: 0;
}

.card-header {
    padding: 0;
    margin-bottom: 0;
    background: none;
    border-bottom: none;
}

.card-body {
    padding: 0;
}


/* ————————————————————————————————————————————————
Global About Page
————————————————————————————————————————————————— */
.g__about-sections {
    margin-top: 10rem;
}

.g__frame-left {
    position: fixed;
    left: 40px;
    width: 20px;
    height: 100vh;
    border-right: 1px solid #353535;
}

.g__frame-right {
    position: fixed;
    right: 60px;
    width: 20px;
    height: 100vh;
    border-right: 1px solid #353535;
}




/* ————————————————————————————————————————————————
///////////////////////////////////////////////////
Mobile Styles
///////////////////////////////////////////////////
————————————————————————————————————————————————— */

/* 1201px — Breakpoint */
@media only screen and (max-width: 1201px) {

    h2 {
        font-size: 155px;
    }

    .g_negative-margin {
        margin-top: -5rem;
        transition: ease 1s;
    }


}


/* 993px — Breakpoint */
@media only screen and (max-width: 993px) {

    h2 {
        font-size: 110px;
    }

    .g_negative-margin {
        margin-top: -4rem;
        transition: ease 1s;
    }

    .g_card-slide,
    .g_project-img,
    .g_meta-container {
        border-radius: 30px;
    }

}


/* 809px — Breakpoint */
@media screen and (max-width: 809px) {
    .g__frame-left,
    .g__frame-right {
        display: none;
    }
}


/* 767px — Breakpoint */
@media only screen and (max-width: 767px) {
    .navbar-collapse {
        padding: 0 10px;
        text-align: center;
    }

    header {
        padding: 80px 0;
    }

    h2 {
        font-size: 70px;
        line-height: 90%;
        margin-top: 2rem;
    }

    .g_negative-margin {
        margin-top: -1.5rem;
        transition: ease 1s;
    }

    .g__design-project__card-slide {
        border-radius: 23px;
    }

    .design-project__meta-container {
        padding-top: 105px;
        height: 145px;
        top: -40px;
    }

    .design-project__container {
        margin-top: -40px;
    }
}



/* 439px — Breakpoint */
@media only screen and (max-width: 439px) {
    h2 {
        font-size: 80px;
        line-height: 90%;
        margin-top: 2rem;
    }

    .design-portfolio__section h2 {
        font-size: 80px;
        line-height: 90%;
        margin-top: 2rem;
    }

    .design-portfolio__section {
        margin-bottom: -2rem;
    }


}


/* 350px — Breakpoint */
@media only screen and (max-width: 350px) {

    .design-project__card-slide {
        top: 93px;
        border-radius: 23px;
    }


}

.header__sub-title {
    color: white;
    max-width: 210px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
}
.header__sub-title-large{
    color: white;
    max-width: 800px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
}
.header-title{
    max-width: 600px;
}

.leftInfoPin {
    color: white;
    top: calc(50%);
    left: 0;
    width: 53px;
    position: fixed;
    text-align: center;
    z-index: 3;
}

.leftInfoPin_link {
    display: block;
    margin-bottom: 5px;
    color: var(--nav-filter-links);
    transition: ease .5s;
    font-size: 18px;
}

@media screen and (max-width: 809px) {
    .leftInfoPin {
        display: none;
    }
}
.rightInfoPin {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    color: white;
    top: calc(50% + 30px);
    right: 0;
    margin-right: -9px;
    position: fixed;
    text-align: center;
    z-index: 3;
}

.rightInfoPin__link {
    display: block;
    color: var(--nav-filter-links);
    transition: ease .5s;
    font-size: 18px;

}
.rightInfoPin__link:hover {
    text-decoration: none;
}
        

@media screen and (max-width: 809px) {
    .rightInfoPin {
        display: none;
    }
}

.bio__link {
    color: var(--primary-blue);
}



.nav__container {
    background: rgb(32 33 32 / 85%);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    box-shadow: 0px 4px 15px #202120;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020;
    display: grid;
    grid-template-columns: 1fr 39px 1fr;
    padding: 20px 16px;
    border-bottom: 1px solid #353535;
    height: -webkit-min-content;
    height: min-content;
}

.nav__container ul {
    list-style-type: none;
    -webkit-margin-before: 0;
            margin-block-start: 0;
    -webkit-margin-after: 0;
            margin-block-end: 0;
    -webkit-margin-start: 0;
            margin-inline-start: 0;
    -webkit-margin-end: 0;
            margin-inline-end: 0;
    -webkit-padding-start: 0;
            padding-inline-start: 0;
}

.nav__logo-container--desktop {
    display: block;
}


/*.nav__logo--desktop {
    margin-right: 8px;
    height: 100px;
    width:100px;
}*/

.nav__logo-container--mobile {
    display: block;
}

.nav__logo--mobile {
    height: 32px;
}

.nav__link-container {
    display: grid;
    grid-template-columns: -webkit-min-content -webkit-min-content;
    grid-template-columns: min-content min-content;
    grid-column-gap: 16px;
    -webkit-column-gap: 16px;
            column-gap: 16px;
    -webkit-align-items: center;
            align-items: center;
}

.nav__link-container--justify {
    -webkit-justify-content: end;
            justify-content: end;
}

.nav__link-container li {
    list-style: none;
}

.nav__link {
    color: white;
    opacity: .5;
    transition: ease .5s;
}

.nav__link:hover,
.nav__link:focus {
    color: white;
    opacity: 1;
    text-decoration: none;
}

  
.nav__link--active {
    color: white;
    opacity: 1;
}


.nav__social {
    font-size: 22px;
    text-align: center;
    display: grid;
    grid-column: 1/-1;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
            column-gap: 10px;
    padding-top: 30px;
}

.nav__social li:nth-last-child(1) {
    margin-top: 30px;
    grid-column: 1/-1;
}

/* Toggle Button */
.navbar-light .navbar-toggler {
    background: none;
}

.nav__resume {
    margin-top: 10px;
    background-color: var(--section-headlines);
    padding: .5rem 1.2rem;
    border-radius: 50px;
    display: block;
    grid-column: 1/-1;
}

.nav__resume:hover,
.nav__resume:focus{
    color: white;
    background-color: var(--mid-grey);
    text-decoration: none;
    transition: ease .5s;

  }

.nav__resume:active,
.nav__resume:visited {
  color: white;
  transition: ease .5s;
}


.nav__toggle-btn {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    justify-self: end;
    width: 30px;
    cursor: pointer;
    transition: all .5s cubic-bezier(0.175, 0.785, 0.12, 1.275);
} 

.nav__toggle-btn__burger {
    width: 30px;
    height: 2px;
    background: transparent;
    border-radius: 5px;
    transition: all .5s cubic-bezier(0.175, 0.785, 0.12, 1.275);
}

.nav__toggle-btn__burger::before,
.nav__toggle-btn__burger::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 2px;
    background: white;
    border-radius: 5px;
    left: 0;
    transition: all .5s cubic-bezier(0.175, 0.785, 0.12, 1.275);
}

.nav__toggle-btn__burger::before {
    -webkit-transform: translateY(-6px);
            transform: translateY(-6px);
}

.nav__toggle-btn__burger::after {
    -webkit-transform: translateY(6px);
            transform: translateY(6px);
}

.nav__toggle-btn.open .nav__toggle-btn__burger {
    background: transparent;
}

.nav__toggle-btn.open .nav__toggle-btn__burger::before {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    background: var(--primary-blue);
}

.nav__toggle-btn.open .nav__toggle-btn__burger::after {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
    background: var(--primary-blue);
}


@media screen and (min-width: 768px) { 

    .nav__social {
        display: none;
    }
    
    .nav__toggle-btn {
        display: none;
    }

    .nav__logo-container--mobile {
        display: none;
    }

}

@media screen and (max-width: 767px) { 

    .nav__container {
        height: 70px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 33px repeat(3, 20%);
        transition: all ease .5s;
    }

    .nav__container--mobile {
        transition: all .5s ease;
        height: 100vh;
    }

    .nav__link-container {
        -webkit-justify-content: center;
                justify-content: center;
        grid-column: 1/-1;
        text-align: center;
        grid-template-columns: 1fr;
    }

    .nav__social,
    .nav__link-container {
        display: none;
    }

    .open--grid {
        display: grid;
    }

    .nav__link-container--justify {
        -webkit-justify-content: center;
                justify-content: center;
    }


    .nav__logo-container--desktop {
        display: none;
    }

    .nav__resume {
        padding: .75rem 1.2rem;
        margin: 10px 0 20px;
    }
}



footer {
    padding: 30px 20px;
    box-shadow: inset 0px 15px 20px 0px rgba(0, 0, 0, 0.20);
    background: var(--darker-gray);
    position:relative;
    left:0;
    bottom:0;
    right:0;
    margin-top: 5rem;

}

.footer__content-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
}

.footer__logo {
    display: block;
    height: 32px;
    margin: 0 auto;
}

.footer__social-icons-container {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    
}

.footer__emoji {
    margin-right: 2px;
}

.footer__react {
    font-size: 23px;
    margin-top: -5px;
    color: #61dafb;
}

.footer__figma {
    font-size: 21px;
    color: #E65631;
}

.footer__built-with {
    margin-top: 30px;
    border-top: solid 1px var(--mid-grey);
    padding: 30px 0 0 0;
    color: white;
    text-decoration: underline;
    opacity: .4;
    text-align: center;
    display: block;
}

.footer__resume {
    color: rgb(233, 233, 233);
    text-align: center;
    background-color: var(--section-headlines);
    max-width: 200px;
    padding: .375rem 1rem;
    border-radius: 50px;
    margin-left: 60px;
}


.footer__social-icons {
    margin-left: 16px;
    color: white;
    transition: ease .5s;
    font-size: 20px;
} 

.footer__social-icons:focus, 
.footer__social-icons:hover,
.footer__built-with:hover,
.footer__social-icons:focus,
.footer__resume:hover,
.footer__resume:focus{
    color: white;
    opacity: .5;
    text-decoration: none;
    transition: ease .5s;

  }
  
  .footer__social-icons:active,
  .footer__social-icons:visited,
  .footer__built-with:visited,
  .footer__built-with:active,
  .footer__resume:active,
  .footer__resume:visited {
    color: white;
    transition: ease .5s;
  }

  @media only screen and (max-width: 439px) { 
    .footer__content-container {
        -webkit-flex-direction: column;
                flex-direction: column;
    }

    .footer__social-icons-container {
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
                justify-content: center;
    }

    .footer__resume {
        margin: 30px auto;
    }

    .footer__built-with {
        padding: 30px 0 0;
    }
  }
