.nav__container {
    background: rgb(32 33 32 / 85%);
    -webkit-backdrop-filter: saturate(180%) blur(20px);
    backdrop-filter: saturate(180%) blur(20px);
    -webkit-box-shadow: 0px 4px 15px #202120;
    box-shadow: 0px 4px 15px #202120;
    position: sticky;
    top: 0;
    z-index: 1020;
    display: grid;
    grid-template-columns: 1fr 39px 1fr;
    padding: 20px 16px;
    border-bottom: 1px solid #353535;
    height: min-content;
}

.nav__container ul {
    list-style-type: none;
    margin-block-start: 0;
    margin-block-end: 0;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 0;
}

.nav__logo-container--desktop {
    display: block;
}


/*.nav__logo--desktop {
    margin-right: 8px;
    height: 100px;
    width:100px;
}*/

.nav__logo-container--mobile {
    display: block;
}

.nav__logo--mobile {
    height: 32px;
}

.nav__link-container {
    display: grid;
    grid-template-columns: min-content min-content;
    column-gap: 16px;
    align-items: center;
}

.nav__link-container--justify {
    justify-content: end;
}

.nav__link-container li {
    list-style: none;
}

.nav__link {
    color: white;
    opacity: .5;
    transition: ease .5s;
}

.nav__link:hover,
.nav__link:focus {
    color: white;
    opacity: 1;
    text-decoration: none;
}

  
.nav__link--active {
    color: white;
    opacity: 1;
}


.nav__social {
    font-size: 22px;
    text-align: center;
    display: grid;
    grid-column: 1/-1;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 10px;
    padding-top: 30px;
}

.nav__social li:nth-last-child(1) {
    margin-top: 30px;
    grid-column: 1/-1;
}

/* Toggle Button */
.navbar-light .navbar-toggler {
    background: none;
}

.nav__resume {
    margin-top: 10px;
    background-color: var(--section-headlines);
    padding: .5rem 1.2rem;
    border-radius: 50px;
    display: block;
    grid-column: 1/-1;
}

.nav__resume:hover,
.nav__resume:focus{
    color: white;
    background-color: var(--mid-grey);
    text-decoration: none;
    transition: ease .5s;

  }

.nav__resume:active,
.nav__resume:visited {
  color: white;
  transition: ease .5s;
}


.nav__toggle-btn {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-self: end;
    width: 30px;
    cursor: pointer;
    transition: all .5s cubic-bezier(0.175, 0.785, 0.12, 1.275);
} 

.nav__toggle-btn__burger {
    width: 30px;
    height: 2px;
    background: transparent;
    border-radius: 5px;
    transition: all .5s cubic-bezier(0.175, 0.785, 0.12, 1.275);
}

.nav__toggle-btn__burger::before,
.nav__toggle-btn__burger::after {
    content: '';
    position: absolute;
    width: 30px;
    height: 2px;
    background: white;
    border-radius: 5px;
    left: 0;
    transition: all .5s cubic-bezier(0.175, 0.785, 0.12, 1.275);
}

.nav__toggle-btn__burger::before {
    -webkit-transform: translateY(-6px);
        -ms-transform: translateY(-6px);
            transform: translateY(-6px);
}

.nav__toggle-btn__burger::after {
    -webkit-transform: translateY(6px);
        -ms-transform: translateY(6px);
            transform: translateY(6px);
}

.nav__toggle-btn.open .nav__toggle-btn__burger {
    background: transparent;
}

.nav__toggle-btn.open .nav__toggle-btn__burger::before {
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
    background: var(--primary-blue);
}

.nav__toggle-btn.open .nav__toggle-btn__burger::after {
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
    background: var(--primary-blue);
}


@media screen and (min-width: 768px) { 

    .nav__social {
        display: none;
    }
    
    .nav__toggle-btn {
        display: none;
    }

    .nav__logo-container--mobile {
        display: none;
    }

}

@media screen and (max-width: 767px) { 

    .nav__container {
        height: 70px;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: 33px repeat(3, 20%);
        transition: all ease .5s;
    }

    .nav__container--mobile {
        transition: all .5s ease;
        height: 100vh;
    }

    .nav__link-container {
        justify-content: center;
        grid-column: 1/-1;
        text-align: center;
        grid-template-columns: 1fr;
    }

    .nav__social,
    .nav__link-container {
        display: none;
    }

    .open--grid {
        display: grid;
    }

    .nav__link-container--justify {
        justify-content: center;
    }


    .nav__logo-container--desktop {
        display: none;
    }

    .nav__resume {
        padding: .75rem 1.2rem;
        margin: 10px 0 20px;
    }
}
