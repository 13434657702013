.header__sub-title {
    color: white;
    max-width: 210px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
}
.header__sub-title-large{
    color: white;
    max-width: 800px;
    margin: 0 auto;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
}
.header-title{
    max-width: 600px;
}
